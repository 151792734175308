import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/scss/lg-thumbnail.scss';
import 'lightgallery/scss/lg-pager.scss';
import 'lightgallery/scss/lg-fullscreen.scss';
import 'lightgallery/scss/lg-video.scss';

import lightGallery from 'lightgallery';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgPager from 'lightgallery/plugins/pager';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from 'lightgallery/plugins/zoom';

import { Application } from 'stimulus';
import ModalController from 'javascripts/stimulus/controllers/modal-controller';
import LocalTime from 'local-time';
import { lightGalleryLicenseKey } from 'constants/lightgallery';

LocalTime.start();

const application = Application.start();
application.register('modal', ModalController);

export default (function () {
  document.addEventListener('DOMContentLoaded', () => {
    const gridImageContainer = document.getElementById('gridImageContainer');

    gridImageContainer.addEventListener('click', (event) => {
      const target = event.target.closest('.cc-grid-image');
      if (!target) return;

      const items = Array.from(
        gridImageContainer.querySelectorAll('.cc-grid-image'),
      ).map((image) => {
        const videoId = image.dataset.videoId;

        if (videoId) {
          return {
            thumb: image.dataset.thumb,
            poster: image.dataset.thumb,
            video: {
              source: [
                {
                  src: image.dataset.playbackUrl,
                  type: 'application/x-mpegURL',
                },
              ],
              attributes: { preload: 'none', controls: true },
            },
          };
        } else {
          return {
            thumb: image.dataset.thumb,
            src: image.dataset.full,
          };
        }
      });

      const index = Array.from(
        gridImageContainer.querySelectorAll('.cc-grid-item'),
      ).indexOf(target.closest('.cc-grid-item'));

      const lgInstance = lightGallery(gridImageContainer, {
        dynamic: true,
        dynamicEl: items,
        videojs: true,
        plugins: [lgFullscreen, lgPager, lgThumbnail, lgVideo, lgZoom],
        showZoomInOutIcons: true,
        actualSizeIcons: {
          zoomIn: 'lg-actual-size',
          zoomOut: 'lg-actual-size',
        },
        showThumbByDefault: false,
        toggleThumb: true,
        pager: false,
        allowMediaOverlap: true,
        licenseKey: lightGalleryLicenseKey,
      });

      lgInstance.openGallery(index);
    });
  });
})();
